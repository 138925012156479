<template>
  <div class="coming_soon d_flex center">
    <h1>Coming Soon!</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.coming_soon{
  height: 100vh;
  h1{
    color: $secondary;
    letter-spacing: 0.06em;
    font-size: 60px;
    text-align: center;
  }
}
</style>